<!-- 综合数据分析 -->
<template>
	<div class="comprehensive_analysis">
		<div class="ca_top">
			<!-- 气象环境监测 -->
			<div class="weather_box">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>气象环境监测</div>
						<div class="line">
							<div class="line1">
								<div class="line3"></div>
							</div>
							<div class="line2">
								<div class="line4"></div>
							</div>
						</div>
					</div>			   
				</div>
				<div class="weather">
					<iframe scrolling="no" src="https://tianqiapi.com/api.php?style=ts&skin=pitaya&color=FFFFFF" frameborder="0" width="100%" height="100%" allowtransparency="true"></iframe>
				</div>
				<div class="data_box" v-if="weatherObj">
					<div class="one">
						<div class="item">
							<div class="label">温度</div>
							<div class="number">{{weatherObj.device_value.value1}}°C</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">光照强度</div>
							<div class="number">{{weatherObj.device_value.value5}}KLux</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">湿度</div>
							<div class="number">{{weatherObj.device_value.value2}}%</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">雨量</div>
							<div class="number">{{weatherObj.device_value.value6}}mm</div>
						</div>
					</div>
					<div class="two">
						<div class="item">
							<div class="label">风速</div>
							<div class="number">{{weatherObj.device_value.value3}}m/s</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">大气压</div>
							<div class="number">{{weatherObj.device_value.value7}}Kpa</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">PM 2.5</div>
							<div class="number">{{weatherObj.device_value.value8}}μg/m³</div>
						</div>
						<div class="data_line"></div>
						<div class="item">
							<div class="label">PM 10</div>
							<div class="number">{{weatherObj.device_value.value9}}μg/m³</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 园区农作物种植分布图 -->
			<div class="distribution">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>园区农作物种植分布图</div>
						<div class="line">
							<div class="line1">
								<div class="line3"></div>
							</div>
							<div class="line2">
								<div class="line4"></div>
							</div>
						</div>
					</div>			   
				</div>
				<div class="d_box">
					<div class="distribution_item">
						<!-- <img src="../../assets/zzfb.png" /> -->
						<img src="../../assets/map/zwfb.png" />
					</div>
					<!-- 园区农业用地面积统计 -->
					<div class="distribution_item">
						<div id="myChart_comprehensive1" ref='comprehensiveChart1'></div>
					</div>
					<!-- 园区农作物种植面积统计 -->
					<div class="distribution_item" >
						<div id="myChart_comprehensive2" ref='comprehensiveChart2'></div>
					</div>
				</div>
			</div>
		</div>
		<div class="ca_bottom">
			<!-- 农业数据分析 -->
			<div class="b_left">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>农业数据分析</div>
						<div class="line">
							<div class="line1">
								<div class="line3"></div>
							</div>
							<div class="line2">
								<div class="line4"></div>
							</div>
						</div>
					</div>			   
				</div>
				<div class="left_chart_box">
					<!-- 24小时农业用地温度/湿度/光照变化趋势统计图 -->
					<div>
						<div id="myChart_comprehensive3" ref='comprehensiveChart3'></div>
					</div>
					<!-- 日光棚月度土壤温度/湿度/光合辐射/二氧化碳浓度极值统计图 -->
					<div>
						<div id="myChart_comprehensive4" ref='comprehensiveChart4'></div>
					</div>
				</div>
			</div>
			<div class="b_middle">
				<!-- 1号日光棚 -->
				<div class="b_middle_item">
					<div class="title">
						<div class="t_l bg2"></div>
						<div class="t_r">
							<div>1号日光棚</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>			   
					</div>
					<div v-for="(item_label,index_label) in labelList1" :key='index_label' class="data_item">
					   <div>{{item_label}}<i></i></div>
					   <span class="data_number" v-if="item_label=='空气温度'">{{realObj.kqwd}}°C</span>
					   <span class="data_number" v-if="item_label=='土壤温度'">{{realObj.trwd}}°C</span>
					   <span class="data_number" v-if="item_label=='空气湿度'">{{realObj.kqsd}}%</span>
					   <span class="data_number" v-if="item_label=='土壤湿度'">{{realObj.trsd}}%</span>
					   <span class="data_number" v-if="item_label=='光合有效辐射'">{{realObj.gh==='null'?0:realObj.gh}}LUX</span>
					   <span class="data_number" v-if="item_label=='最近灌溉时间'">{{realObj.gg_time}}</span>
					</div>
				</div>
				<!-- 预警信息 -->
				<div class="b_middle_item">
					<div class="title">
						<div class="t_l"></div>
						<div class="t_r">
							<div>预警信息</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>			   
					</div>
					<div class="box">
						<div v-for="(item,index) in warnList" :key='item.id' class="warn_item">
						  <span :class="item.level==2?'red':'quan'"></span>
						  <span>{{timeFormate(item.warn_time)}} {{item.warn_content}}</span>
						</div>
					</div>
					
				</div>
				<!--能源数据分析 I-->
				<div class="b_middle_item">
					<div class="title">
						<div class="t_l"></div>
						<div class="t_r">
							<div>能源数据分析 I</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>			   
					</div>
					<div v-for="(item_label,index_label) in labelList2" :key='item_label.id' class="data_item">
					   <div>{{item_label.energy_name}}<i></i></div>
					   <span class="data_number">{{item_label.count}}</span>
					</div>
				</div>
			</div>
			<div class="b_right">
				<div class="b_right_top">
					<!-- 农作物产值预测 -->
					<div class="b_right_top_item">
						<div class="title">
								<div class="t_l"></div>
								<div class="t_r">
									<div>农作物产值预测</div>
									<div class="line">
										<div class="line1">
											<div class="line3"></div>
										</div>
										<div class="line2">
											<div class="line4"></div>
										</div>
									</div>
								</div>			   
						</div>
						<div class="list_title">
							<div>种植类型</div>
							<div>饱和数据</div>
							<div>实际数据</div>
							<div>增长率</div>
						</div>
						<div class="sensor_list">
							<div class="sensor_item" v-for="(item,index) in produceList" :key='index'>
								<div>{{item.vegetables}}</div>
								<div class="blue">{{item.saturated}}亩</div>
								<div class="yellow">{{item.actual_saturated}}亩</div>
								<div :class="Number(item.increase_rate.split('%')[0])>0?'green':'red1'"><i :class="Number(item.increase_rate.split('%')[0])>0?'el-icon-top':'el-icon-bottom'"></i>{{item.increase_rate}}</div>
							</div>
						</div>
					</div>
					<!-- 劳动人员出勤次数统计 -->
					<div class="b_right_top_item">
						<div class="title">
								<div class="t_l"></div>
								<div class="t_r">
									<div>劳动人员出勤次数统计</div>
									<div class="line">
										<div class="line1">
											<div class="line3"></div>
										</div>
										<div class="line2">
											<div class="line4"></div>
										</div>
									</div>
								</div>			   
						</div>
						<div id="myChart_comprehensive5" ref='comprehensiveChart5'></div>
					</div>
				</div>
				<!-- 能源数据分析2 -->
				<div class="b_right_bottom">
					<div class="title">
						<div class="t_l"></div>
						<div class="t_r">
							<div>能源数据分析 II</div>
							<div class="line">
								<div class="line1">
									<div class="line3"></div>
								</div>
								<div class="line2">
									<div class="line4"></div>
								</div>
							</div>
						</div>			   
					</div>
					<div class="nyfx_chart">
						<div id="myChart_comprehensive6" ref='comprehensiveChart6'></div>
						<div id="myChart_comprehensive7" ref='comprehensiveChart7'></div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { getMeteorologicalMonitor,yard_area,vegetables_area,vegetables_saturated,getWarningList,getAgriculturalLandMonitor,realtimeDeviceMonitor,getMonthCumulativeElectric,getRGMonthMonitor,getMonthClockCount,new_Energy_Proportion,getCumulativeElectric} from '@/api/index'
import { sendMessageWebsocket } from '@/api/websocket'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
import 'echarts-liquidfill/src/liquidFill.js'
export default {
  name: 'ComprehensiveDataAnalysis',
  data(){
	  return{
		timeId:[],
		weatherObj:{device_value:{value1:''}},
		labelList1:['空气温度','土壤温度','空气湿度','土壤湿度','光合有效辐射','最近灌溉时间'],
		warnList:[],
		labelList2:['发电设备数量','充电桩数量','储能设备数量','用电区域数量'],
		produceList:[],//农作物产值表
		 xName1:[],////当前各地名称
		 data1:[],//区农业用地面积统计
		  yName:[],////所有农产品名称
		  data2:[],//农产品占地面积
		  yAxisName:['°C','%','LUX'],//y轴名称
		  legendList3:['温度','湿度','光合有效辐射'],//折线名称集合
		  xAxisName3:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
		  legendList4:['温度','湿度','光合有效辐射','二氧化碳浓度'],//折线名称集合
		  xAxisName4:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
		  dataList3:{
		  			// trwd:[10,1,2,13,4,5,6,17,8,5,10,11,12,13,14,25,16,7,18,19,0,11,1,0,24],
		  			// trsd:[20,30,40,30,4,15,6,7,8,29,10,11,75,78,14,15,12,17,18,19,1,21,46,85,27],
		  			// gh:[100,110,52,317,42,5,65,7,8,99,109,11,412,43,94,95,76,47,28,19,88,29,66,100,211],
		  },
		  dataList4:{
		     wd:[20,21,20,21,22,21,23,24,24,24,24,27,27],
		     sd:[30,29,30,28,32,29,28,27,30,28,27,26,26],
		     gh:[4292,5422,5219,4918,6120,5921,5120,5210,5922,6329,4192,6381,7982],
		     co2:[0.4,0.6,0.5,0.3,0.4,0.4,0.1,0.2,0.3,0.5,0.7,0.1,0.2],
		  }, //日光棚月度土壤温度等值统计图
		  data5:[71,66,18,32,24,81,58,66,25,48,56,66,18],//每天出勤次数
		  data6:{},
		  data7: [0.6655, 0.5, 0.4,0.1],//数据是个数组 数组的每一项的值是0-1
		  realObj:{}
	}
  },
  mounted () {
		this.getWeather()
		// this.getYardArea()
		// this.getVegetablesArea()
		this.getVegetablesSaturated()
		this.getWarning()
		this.agriculturalLandMonitor()
		this.getRealData()
		this.monthCumulativeElectric()
		this.rgMonthMonitor()
		this.monthClockCount()
		this.energyProportion()
		this.cumulativeElectri()
  },
  destroyed() {
  		  console.log('destroyed')
  		  console.log(this.timeId)
  		  // 清空该页面中所有的的定时器
  		  for(let i=0;i<this.timeId.length;i++){
  			   clearInterval(this.timeId[i])
  		  }
		  // 销毁图表实列，释放echarts资源
		  echarts.dispose(this.myChart1);
		  echarts.dispose(this.myChart2);
		  echarts.dispose(this.myChart3);
		  echarts.dispose(this.myChart4);
		  echarts.dispose(this.myChart5);
		  echarts.dispose(this.myChart6);
		  this.myChart1 = null;
		  this.myChart2 = null;
		  this.myChart3 = null;
		  this.myChart4 = null;
		  this.myChart5 = null;
		  this.myChart6 = null;
  },
  methods:{
	getWeather () {
	  // 获取气象数据
	  const data = {
	    device_model: '',//型号
	    yard_id: ''//场地
	  }
	  // 请求数据
	  getMeteorologicalMonitor(data)
	    .then((res) => {
		  // console.log(res.data)
		  this.weatherObj=res.data
		  this.$nextTick(function () {
		     this.getYardArea()
		     this.getVegetablesArea()
		  })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 园区农业用地面积统计
	getYardArea () {
	  const data = {}
	  // 请求数据
	  yard_area(data)
	    .then((res) => {
	      console.log('园区农业用地面积统计')
		  console.log(res.data)
		  this.xName1=res.data.xname
		  this.data1=res.data.data1
		  // this.drawLine1()
		  this.$nextTick(function () {
			  console.log(1)
		    this.drawLine1()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 园区农作物种植面积统计
	getVegetablesArea () {
	  const data = {}
	  // 请求数据
	  vegetables_area(data)
	    .then((res) => {
	      console.log('园区农作物种植面积统计')
		  console.log(res.data)
		  this.yName=res.data.yname
		  this.data2=res.data.data1
		  this.$nextTick(function () {
			  console.log(2)
		    this.drawLine2()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 农作物产值预算
	getVegetablesSaturated () {
	  const data = {}
	  // 请求数据
	  vegetables_saturated(data)
	    .then((res) => {
	      console.log('农作物产值预算')
		  console.log(res.data)
		  this.produceList=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//获取预警信息
	getWarning () {
	  const data = {}
	  // 请求数据
	  getWarningList(data)
	    .then((res) => {
	      console.log('获取预警信息')
		  console.log(res.data)
		  this.warnList=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 24小时农业用地温度湿度光照趋势图
	agriculturalLandMonitor () {
	  const data = {
		  yard_id:1//默认第一块地
	  }
	  // 请求数据
	  getAgriculturalLandMonitor(data)
	    .then((res) => {
		  console.log(" 24小时农业用地温度湿度光照趋势图")
		  console.log(res.data)
		  if(res.code===200){
			  this.dataList3=res.data
			  // this.drawLine3()
			  this.$nextTick(function () {
				  // console.log('333')
			      this.drawLine3()
			   })
			}
		})
	},	
	// 获取1号日光棚信息
	getRealData () {
	  const data = {
		  yard_id:1
	  }
	  // 请求数据
	  realtimeDeviceMonitor(data)
	    .then((res) => {
		  console.log("获取1号日光棚实时数据")
		  console.log(res.data)
		  if(res.code===200){
			  this.realObj=res.data
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 能源数据分析(当月累计用电量)
	monthCumulativeElectric () {
	  const data = {}
	  // 请求数据
	  getMonthCumulativeElectric(data)
	    .then((res) => {
	      console.log('能源数据分析(当月累计用电量)')
		  console.log(res.data)
		  this.data6=res.data
		  this.$nextTick(function () {
		    this.drawLine6()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//  日光棚月度土壤温度/湿度/光合/二氧化碳
	rgMonthMonitor () {
	  const data = {}
	  // 请求数据
	  getRGMonthMonitor(data)
	    .then((res) => {
	      console.log(' 日光棚月度土壤温度/湿度/光合/二氧化碳')
		  console.log(res.data)
		  this.dataList4=res.data
		  this.$nextTick(function () {
		    this.drawLine4()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 劳动人员出勤统计
	monthClockCount () {
	  const data = {}
	  // 请求数据
	  getMonthClockCount(data)
	    .then((res) => {
	      console.log('劳动人员出勤统计')
		  console.log(res.data)
		  this.data5=res.data.arr
		  this.$nextTick(function () {
		    this.drawLine5()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 新能源占比
	energyProportion () {
	  const data = {}
	  // 请求数据
	  new_Energy_Proportion(data)
	    .then((res) => {
	      console.log('新能源占比')
		  console.log(res.data)
		  // console.log(parseFloat(res.data.proportion))
		  this.data7[0]=parseFloat(res.data.proportion)/100
		  this.$nextTick(function () {
		    this.drawLine7()
		   })
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 能源数据分析
	cumulativeElectri () {
	  const data = {}
	  // 请求数据
	  getCumulativeElectric(data)
	    .then((res) => {
	      console.log('能源数据分析I')
		  console.log(res.data)
		  this.labelList2=res.data.list
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	//  园区农业用地面积统计
	drawLine1 () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart1 = echarts.init(document.getElementById('myChart_comprehensive1'));
			           this.myChart1.resize({
			             width: this.$refs.comprehensiveChart1.clientWidth,
			             height: this.$refs.comprehensiveChart1.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   title: {
						        text: ' 园区农业用地面积统计',
						    	textStyle:{
						    		color: '#A5EEEB',
									fontWeight: 400,
									fontSize:12
						    	},
						    },
							toolbox: {
							  feature: {
							    saveAsImage: {
									show:true,
							        backgroundColor :'balck',
									pixelRatio: 3
								}
							  },
							},
						    grid: {
						        left: '1%',
						        right: '1%',
						        bottom: '3%',
						        containLabel: true
						    },
						    
						    tooltip: {
						        show: "true",
						        trigger: 'axis',
								backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
								borderColor:'gray',
								textStyle:{
									color: '#FFFFFF',
									align:'left',///标签内容左对齐
									fontWeight:400,
								},
						        axisPointer: { // 坐标轴指示器，坐标轴触发有效
						            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						        }
						    },
						    yAxis: {
						        name:'㎡',
						        nameTextStyle: {
						            fontSize: 10,
						            fontFamily: 'Microsoft YaHei',
						            color: '#fff'
						        },
						        type: 'value',
						        axisTick: {
						            show: false
						        },
						        axisLine: {
						            show: true,
						            lineStyle: {
						                color: '#535A6B',
						            },
						        },
						        splitLine: {
						            show: false,
						            lineStyle: {
						                color: '#FFFFFF',
						            }
						        },
						    	axisLabel: {
						    	    fontSize: 12,
						    	    fontFamily: 'Microsoft YaHei',
						    	    color: "#FFFFFF",
						    	},
						    },
						    xAxis: [
						        {
						            type: 'category',
						            axisTick: {
						                show: false
						            },
						            axisLine: {
						                show: true,
						                lineStyle: {
						                    color: '#535A6B',
						                }
						            },
						            axisLabel: {
						            fontSize: 10,
						            fontFamily: 'Microsoft YaHei',
						            color: "#FFFFFF",
						            interval: 0,
						            rotate: 40,
						            margin: 10,
						            
						        },
						            data: this.xName1
						        }
						    
						    ],
						    series: [
						        {
						            name: '用地面积(㎡)',
						            type: 'bar',
						            itemStyle: {
						                normal: {
						                    show: true,
						                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						                        offset: 0,
						                        color: 'rgba(54,207,201,0.3)'
						                    },{
						                        offset: 1,
						                        color: 'rgba(54,207,201,1)'
						                    }]),
						                    barBorderRadius: 50,
						                    borderWidth: 0,
						                    borderColor: '#333',
						                }
						            },
						            label: {
						                normal: {
						                    show: true,
						                    position: 'top',
						                    textStyle: {
						                        color: '#fff'
						                    }
						                }
						            },
						            barWidth: '20%',
						            data: this.data1,
						        }
						    ]
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart1.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart1,option,1)
	},
	//  园区农作物种植面积统计
	drawLine2 () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart2 = echarts.init(document.getElementById('myChart_comprehensive2'));
			           this.myChart2.resize({
			             width: this.$refs.comprehensiveChart2.clientWidth,
			             height: this.$refs.comprehensiveChart2.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   title: {
						        text: ' 园区农作物种植面积统计',
						    	textStyle:{
						    		color: '#A5EEEB',
									fontWeight: 400,
									fontSize:12
						    	},
						    },
							toolbox: {
							  feature: {
							    saveAsImage: {
									show:true,
							        backgroundColor :'balck',
									pixelRatio: 3
								}
							  },
							},
						    grid: {
						        left: '1%',
						        right: '1%',
						        bottom: '3%',
						        containLabel: true
						    },
						    
						    tooltip: {
						        show: "true",
						        trigger: 'axis',
								backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
								borderColor:'gray',
								textStyle:{
									color: '#FFFFFF',
									align:'left',///标签内容左对齐
									fontWeight:400,
								},
						        axisPointer: { // 坐标轴指示器，坐标轴触发有效
						            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						        }
						    },
						    yAxis: {
						        nameTextStyle: {
						            fontSize: 10,
						            fontFamily: 'Microsoft YaHei',
						            color: '#fff'
						        },
						        type: 'category',
						        axisTick: {
						            show: false
						        },
						        axisLine: {
						            show: true,
						            lineStyle: {
						                color: '#535A6B',
						            },
						        },
						        splitLine: {
						            show: false,
						            lineStyle: {
						                color: '#FFFFFF',
						            }
						        },
						    	axisLabel: {
						    	    fontSize: 12,
						    	    fontFamily: 'Microsoft YaHei',
						    	    color: "#FFFFFF",
									// interval: 0,
									// rotate: 40,
									// margin: 10,
						    	},
								data:this.yName
						    },
						    xAxis: [
						        {
						            type: 'value',
									name:'㎡',
						            axisTick: {
						                show: false
						            },
						            axisLine: {
						                show: true,
						                lineStyle: {
						                    color: '#535A6B',
						                }
						            },
									splitLine:{
										show:false
									},
						            axisLabel: {
						            fontSize: 10,
						            fontFamily: 'Microsoft YaHei',
						            color: "#FFFFFF",
						            interval: 0,
						            rotate: 40,
						            margin: 10,
						            
						        },
						            // data: this.xName1
						        }
						    
						    ],
						    series: [
						        {
						            name: '用地面积(㎡)',
						            type: 'bar',
						            itemStyle: {
						                normal: {
						                    show: true,
						                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						                        offset: 0,
						                        color: 'rgba(237,152,69,0.9)'
						                    },{
						                        offset: 1,
						                        color: 'rgba(237,152,69,1)'
						                    }]),
						                    barBorderRadius: 50,
						                    borderWidth: 0,
						                    borderColor: '#333',
						                }
						            },
						            label: {
						                normal: {
						                    show: false,
						                    position: 'top',
						                    textStyle: {
						                        color: '#fff'
						                    }
						                }
						            },
						            barWidth: '20%',
						            data: this.data2,
						        }
						    ]
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart2.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart2,option,2)
	},
	// 24小时农业用地温度/湿度/光照变化趋势统计图
	drawLine3 () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart3 = echarts.init(document.getElementById('myChart_comprehensive3'));
			           this.myChart3.resize({
			             width: this.$refs.comprehensiveChart3.clientWidth,
			             height: this.$refs.comprehensiveChart3.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00','#ED9845'],
						   title: {
						        text: '24小时农业用地温度/湿度/光照变化趋势统计图',
						    	textStyle:{
						    		color: '#A5EEEB',
									fontSize:12,
									fontWeight:400
						    	},
						    },
						    tooltip: {
						      trigger: 'axis',
							  backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							  borderColor:'gray',
						      axisPointer: {
						        type: 'cross',
						        label: {
						          backgroundColor: '#6a7985'
						        }
						      },
							  textStyle:{
							  　　align:'left',///标签内容左对齐
							      color:"#FFFFFF"
						
							  },
							  // 自定义节点标签内容
							  formatter(params){
							  	  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
							  	  const Y = date.getFullYear() + '年'
							  	  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
							  	  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
							  	  let time= Y + M + D 
							  	  let str=time+'  '+params[0].axisValue+'时'+'<br>'						   
							      params.forEach((item, idx) =>{
							  		str+=item.marker+' '+item.seriesName+' : '+item.data
							  		switch (idx){
							  		     case 0:
							  		         str += '°C';
							  		         break;
							  		     case 1:
							  		         str += '%';
							  		         break;
							  		     case 2:
							  		         str += 'LUX';
							  		         break;
							  			 case 3:
							  			     str += '°C';
							  			     break;
							  			 case 4:
							  			     str += '%';
							  			     break;
							  			 case 5:
							  			    if(item.seriesName=='雨量变化'){
							  					str += 'mm';
							  				}else{
							  					str += '%';
							  				}
							  			    
							  			    break;
							  		     default:
							  		         str += '';		    
							  		}							
							  		str += idx === params.length -1? '': '<br/>'							   
							      })   
							  		return str;
							   }
						    },
						    legend: {
						      data:this.legendList3,
						      textStyle:{
						      	  color: '#FFFFFF'
						      },
						      // orient: 'vertical',  //垂直显示
						      y: '30',    //延Y轴居中
						      x: 'center' ,//居右显示
							  itemWidth:30,//图例的宽度
							  itemHeight:10,//图例的高度
						    },
						    toolbox: {
						      feature: {
						        saveAsImage: {
						    		show:true,
						            backgroundColor :'balck',
						    		pixelRatio: 3
						    	}
						      },
						    },
						    grid: {
						      left: '3%',
						      right: '5%',
						      bottom: '0%',
							  top:'50',
						      containLabel: true
						    },
						    xAxis: [
						      {
						        type: 'category',
						        boundaryGap: false,
								splitLine:{
									show:false
								},
								// 设置坐标轴字体颜色和宽度
								axisLine:{
									lineStyle: {
									    color: '#FFFFFF'
									}	,
								},
						        data: this.xAxisName3
						      }
						    ],
						    yAxis: [
						     { // 第一个y轴位置在左侧 温度
						    position: 'left',
						    type: 'value',
						    name: this.yAxisName[0],
						    // name: this.Ystyle(this.yAxisName[0]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]   //想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的名称颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      // color: '#FFFFFF',
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第二个y轴在右侧
						    position: 'right',
						    type: 'value',
						    name: this.yAxisName[1],
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第三个y轴也在左侧，距第二个60个像素
						    position: 'left',
						    offset: 30,
						    type: 'value',
						    name: this.yAxisName[2],
						    // name: this.Ystyle(this.yAxisName[2]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 10, // 名称与坐标轴间距
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						 
						],
						    series: [
						      {
						        name: this.legendList3[0],
						        type: 'line',
								yAxisIndex: 0,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(128, 255, 165)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(1, 191, 236)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [140, 232, 101, 264, 90, 340, 250]
								data: this.dataList3.trwd
						      },
						      {
						        name: this.legendList3[1],
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(0, 221, 255)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(77, 119, 255)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [120, 282, 111, 234, 220, 340, 310]
								data: this.dataList3.trsd
						      },
						      {
						        name: this.legendList3[2],
						        type: 'line',
								yAxisIndex: 2,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(55, 162, 255)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(116, 21, 219)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [320, 132, 201, 334, 190, 130, 220]
								data: this.dataList3.gh
						      },
						     
						    ]
			           };
					   
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart3.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart3,option,3)
	},
	// 日光棚月度土壤温度/湿度/光照/二氧化碳极值统计图
	drawLine4 () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart4 = echarts.init(document.getElementById('myChart_comprehensive4'));
			           this.myChart4.resize({
			             width: this.$refs.comprehensiveChart4.clientWidth,
			             height: this.$refs.comprehensiveChart4.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#ED9845', '#FFBF00','#FF0087','#36CFC9'],
						   title: {
						        text: '日光棚月度土壤温度/湿度/光照/二氧化碳极值统计图',
						    	textStyle:{
						    		color: '#A5EEEB',
									fontSize:12,
									fontWeight:400
						    	},
						    },
						    tooltip: {
						      trigger: 'axis',
						      axisPointer: {
						        type: 'cross',
						        label: {
						          backgroundColor: '#6a7985'
						        }
						      },
							  backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							  borderColor:'gray',
							  textStyle:{
							  　　align:'left',///标签内容左对齐
							     color: '#FFFFFF',
							  },
							  // 自定义节点标签内容
							  formatter(params){
								  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
								  const Y = date.getFullYear() + '年'
								  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
								  							
								  let time= Y + M 
								  let str=time+'  '+params[0].axisValue+'日'+'<br>'	
							      params.forEach((item, idx) =>{
							  		str+=item.marker+' '+item.seriesName+' : '+(item.data==='null'?0:item.data)
							  		switch (idx){
							  		     case 0:
							  		         str += '°C';
							  		         break;
							  		     case 1:
							  		         str += '%';
							  		         break;
							  		     case 2:
							  		         str += 'LUX';
							  		         break;
							  			 case 3:
							  			     str += '%';
							  			     break;
							  		     default:
							  		         str += '';		    
							  		}							
							  		str += idx === params.length -1? '': '<br/>'							   
							      })   
							  		return str;
							   }
						    },
						    legend: {
						      data:this.legendList4,
						      textStyle:{
						      	  color: '#FFFFFF'
						      },
						      // orient: 'vertical',  //垂直显示
						      y: '30',    //延Y轴居中
						      x: 'center' ,//居右显示
							  itemWidth:20,//图例的宽度
							  itemHeight:10,//图例的高度
						    },
						    toolbox: {
						      feature: {
						        saveAsImage: {
						    		show:true,
						            backgroundColor :'balck',
						    		pixelRatio: 3
						    	}
						      },
						    },
						    grid: {
						      left: '3%',
						      right: '5%',
						      bottom: '0%',
							  top:'50',
						      containLabel: true
						    },
						    xAxis: [
						      {
						        type: 'category',
						        boundaryGap: false,
								splitLine:{
									show:false
								},
								// 设置坐标轴字体颜色和宽度
								axisLine:{
									lineStyle: {
									    color: '#FFFFFF'
									}	,
								},
						        data: this.xAxisName4
						      }
						    ],
						    yAxis: [
						     { // 第一个y轴位置在左侧 温度
						    position: 'left',
						    type: 'value',
						    name: this.yAxisName[0],
						    // name: this.Ystyle(this.yAxisName[0]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]   //想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的名称颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      // color: '#FFFFFF',
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第二个y轴在右侧
						    position: 'right',
						    type: 'value',
						    name: this.yAxisName[1],
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第三个y轴也在左侧，距第二个60个像素
						    position: 'left',
						    offset: 30,
						    type: 'value',
						    name: this.yAxisName[2],
						    // name: this.Ystyle(this.yAxisName[2]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 10, // 名称与坐标轴间距
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						 
						],
						    series: [
						      {
						        name: this.legendList4[0],
						        type: 'line',
								yAxisIndex: 0,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255, 191, 0)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(224, 62, 76)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [140, 232, 101, 264, 90, 340, 250]
								data: this.dataList4.wd
						      },
						      {
						        name: this.legendList4[1],
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(226,202,69,1)'
						            },
						            {
						              offset: 1,
						              color: '#FFBF00'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [120, 282, 111, 234, 220, 340, 310]
								data: this.dataList4.sd
						      },
						      {
						        name: this.legendList4[2],
						        type: 'line',
								yAxisIndex: 2,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255,0,135,1)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(255,0,135,0.4)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [320, 132, 201, 334, 190, 130, 220]
								data: this.dataList4.gh
						      },{
						        name: this.legendList4[3],
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(54,207,201,1)'
						            },
						            {
						              offset: 1,
						              color: '#39DBD5'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [320, 132, 201, 334, 190, 130, 220]
								data: this.dataList4['2y']
						      },
						     
						    ]
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart4.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart4,option,4)
	},
	// 劳动人员出勤次数统计
	drawLine5() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart5 = echarts.init(document.getElementById('myChart_comprehensive5'));
			           this.myChart5.resize({
			             width: this.$refs.comprehensiveChart5.clientWidth,
			             height: this.$refs.comprehensiveChart5.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    title: {
					        text: "劳动人员出勤次数统计",
					        x:'left',
					        textStyle: {
					            color: '#1FFDFD',
					            fontWeight: 'normal',
					            fontSize: 12
					        }
					    },
					    grid: {
					        left: '1%',
					        right: '5%',
					        bottom: '8%',
							top:40,
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
							backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							borderColor:'gray',
							textStyle: {
							    color: '#FFFFFF',
								align:'left',///标签内容左对齐
							},
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
					        },
							// 自定义节点标签内容
							formatter(params){
								  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
								  const Y = date.getFullYear() + '年'
								  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
							
								  let time= Y + M 
								  let str=time+'  '+params[0].axisValue+'日'+'<br>'						   
							    params.forEach((item, idx) =>{
									str+=item.marker+' '+item.seriesName+' : '+item.data
									
							    })   
									return str;
							 }
					    },
						toolbox: {
						  feature: {
							magicType: { type: ['line', 'bar'] },
						    saveAsImage: {
								show:true,
						        backgroundColor :'balck',
								pixelRatio: 3
							}
						  },
						},
					    yAxis: {
					        // name:'次',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					        },
					            data: this.xAxisName4
					        }
					    
					    ],
					    series: [
					        {
					            name: '出勤次数（次）',
					            type: 'line',
								areaStyle: {
									//改变区域渐变颜色
									normal: {
									    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									            offset: 0,
									            color: 'rgba(0,255,230,1)'
									        }, {
									            offset: 1,
									            color: 'rgba(0,255,230,0.2)'
									        }]),            
									}									
								},
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: '#00FFE6'
					                    }, {
					                        offset: 1,
					                        color: '#007CC6'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data5,
							    markPoint: {
							          data: [
							            { type: 'max', name: 'Max' },
							            { type: 'min', name: 'Min' }
							          ]
							        },
							   markLine: {
							       data: [{ type: 'average', name: 'Avg' }]
							   }
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart5.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart5,option,5)
						   
	},
    // 新能源发电量与设备用电量趋势
    drawLine6() {
    		   // 基于准备好的dom，初始化echarts实例
    		           this.myChart6 = echarts.init(document.getElementById('myChart_comprehensive6'));
    		           this.myChart6.resize({
    		             width: this.$refs.comprehensiveChart6.clientWidth,
    		             height: this.$refs.comprehensiveChart6.clientHeight
    		           })
    		           // 指定图表的配置项和数据
    		           var option = {
    					   ////动画效果
    					   animationDuration: 10000,
    					   animationDurationUpdate: 3000,
    					   animationEasing: 'linear',
    					   animationEasingUpdate: 'linear',
    		               title: {
    		                   text: '新能源发电量与设备用电量趋势',
    						   textStyle:{
    						   	color: '#A5EEEB',
								fontSize:12,
								fontWeight:400
    						   }
    		               },
    					   color: ['#36CFC9', '#ED9845'],
    					   legend: {
    					     data: ['发电量', '用电量'],
    						 textStyle:{
    							  color: '#FFFFFF'
    						 },
    						 x: 'center' //居右显示
    					   },
    					   // 节点标签
    		               tooltip: {
    						   trigger: 'axis',
    						   axisPointer: {
    						     type: 'cross',
    						     label: {
    						       backgroundColor: '#6a7985'
    							   // backgroundColor: 'red'
    						     }
    						   },
    						   backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
    						   borderColor:'gray',
    						   textStyle:{
    							align:'left',///标签内容左对齐
    						   	color: '#FFFFFF'
    						   },
    						   
    						   // 自定义节点标签内容
    						   formatter(params){
								   var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
								   const Y = date.getFullYear() + '年'
								   const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
								   							
								   let time= Y + M 
								   let str=time+'  '+params[0].axisValue+'日'+'<br>'	
    						       params.forEach((item, idx) =>{
    								   str+=item.marker+' '+item.seriesName+' : '+item.data
    								   str += idx === params.length -1? '': '<br/>'
    						       })   
    							    return str;
    						    }
    					   },
						   toolbox: {
						     feature: {
						       saveAsImage: {
						   		show:true,
						           backgroundColor :'balck',
						   		pixelRatio: 3
						   	}
						     },
						   },
    					   // 调整图表的坐标轴区域距离整个图表左右的间距
    					   grid: {
    					     left: '0%',
    					     right: '2%',
    					     bottom: '0%',
							 top:30,
    					     containLabel: true
    					   },
    		               xAxis: {
    						   data:this.xAxisName4,
    						   type: 'category',
    						   boundaryGap: false,
    						   splitLine:{
    							   show:false
    						   },
    						   // 设置坐标轴字体颜色和宽度
    						   axisLine:{
    							   lineStyle: {
    							       color: '#FFFFFF'
    							   }
    						   }
    		               },
    		               yAxis: {
    						   splitLine:{
    						   	  show:false
    						   },
    						   // 设置坐标轴字体颜色和宽度
    						   axisLine:{
    						   		lineStyle: {
    						   		    color: '#FFFFFF'
    						   		}						   
    						   }
    					   },
    		               series: [
    						{
    		                   name: '用电量',
    		                   type: 'bar',
    						   data:this.data6.yongdian,
    						   smooth: false,//设置折线的平滑度
    						   lineStyle:{
    						   	color:'yellow',
    						   },
    						   itemStyle: {
    						       normal: {areaStyle: {type: 'default'}}    
    						   },
    						   areaStyle: {
    										 //改变区域渐变颜色 
    										normal: {
    										    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    										            offset: 0,
    										            color: 'rgba(237,152,69,1)'
    										        }, {
    										            offset: 1,
    										            color: 'rgba(237,152,69,0.2)'
    										        }]),            
    										}
    						   			},
    						   			itemStyle: {
    						   				normal: {
    						   					color: 'rgba(237,152,69,0.8)', //改变折线点的颜色
    						   					lineStyle: {
    						   						color: 'rgba(237,152,69,0.8)' ,//改变折线颜色
    						   					}
    						   				}
    						   			},
    		               },
    					   {
    					       name: '发电量',
    					       type: 'line',
    					       data:this.data6.fadian,
    					       smooth: false,//设置折线的平滑度
    					       lineStyle:{
    					       	color:'yellow',
    					       },
    					       itemStyle: {
    					           normal: {areaStyle: {type: 'default'}}    
    					       },
    					       areaStyle: {
    					       		//改变区域渐变颜色
    					       		normal: {
    					       		    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    					       		            offset: 0,
    					       		            color: 'rgba(54,207,201,1)'
    					       		        }, {
    					       		            offset: 1,
    					       		            color: 'rgba(54,207,201,0.2)'
    					       		        }]),            
    					       		}				       										
    					       },
    					       itemStyle: {
    					       	normal: {
    					       		color: 'rgba(54,207,201,0.8)', //改变折线点的颜色
    					       		lineStyle: {
    					       			color: 'rgba(54,207,201,0.8)' ,//改变折线颜色
    					       		}
    					       	}
    					       },									   
    					   }
    					]
    		           };
    		           // 使用刚指定的配置项和数据显示图表。
    		           this.myChart6.setOption(option);
    				   
    				   //动态循环显示数据节点
    				   this.dataLableChange(this.myChart6,option,6)
    },
	//当前新能源供应电力占比
	drawLine7() {
			   // 基于准备好的dom，初始化echarts实例
			           var myChart = echarts.init(document.getElementById('myChart_comprehensive7'));
			           myChart.resize({
			             width: this.$refs.comprehensiveChart7.clientWidth,
			             height: this.$refs.comprehensiveChart7.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    title: {
					        text: "当前新能源供应电力占比",
					        x:'center',
					        textStyle: {
					            color: '#1FFDFD',
					            fontWeight: 'normal',
					            fontSize: 12
					        }
					    },
						series: [{
						    type: 'liquidFill',
						    data: this.data7,
						    radius: '80%',
						    // 水球颜色
						    color: ['#1d9596', '#24bec0', '#25e1e3','#00fcff'],
						    center: ['50%', '60%'],
						    // outline  外边
						    outline: {
						        // show: false
						        borderDistance: 5,
						        itemStyle: {
						            borderWidth: 2,
						            borderColor: '#13FDCE',
						        },
						    },
						    label: {
						        normal: {
						            color: '#FFF',
									 // color: 'red',
						            insideColor: '#fff',
						            fontSize: 24,
						            fontWeight:600,
						            formatter : function(res){
										// console.log(res)
										let str=(res.value*100).toFixed(2)+'%'
						            	return str;
						            }
						        }
						    },
						    // 内图 背景色 边
						    backgroundStyle: {
						        color: 'rgba(4,24,74,0.8)',
						    }
						}]
						             
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           myChart.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(myChart,option,7)
						   
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	// 纵坐标的值竖着显示的实现方法
	Ystyle (value) {
	  // console.log(value)
	  if (value) {
	    // 如果传入的值不为空的时候把y轴的文字改为竖版显示
	    const str = value.split('')
	    return str.join('\n')
	  }
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '年'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},
  },
  // 监听屏幕变化
  watch: {
   
	
  }
}
</script>

<style scoped="scoped">
.comprehensive_analysis {
    width: 100%;
    height: 100%;
	padding: 30px;
	box-sizing: border-box;
  }
.ca_top{
	width: 100%;
	/* height:352px ; */
	height: calc(40% - 15px);
	/* background-color: #36CFC9; */
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.weather_box{
	width: 480px;
	/* height: 352px; */
	height: 100%;
	margin-right: 30px;
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
	background: rgba(10,23,56,0.7);
}
.title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
/* 	  margin-bottom: 2%; */
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	  background: url(../../assets/jiankong/quan_o.png);
    	  background-size: contain;
    	  margin-right: 14px;
    }
	.bg2{
		  background: url(../../assets/jiankong/quan_b.png); 
		  background-size: contain;
	}
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	     justify-content: space-between;
  	     align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
.weather{
	  width: 400px;
	  /* height: 200px; */
	  height:calc(100% - 142px) ;
	  position: relative;
	  left: 50%;
	  top: 3px;
	  transform: translateX(-50%);
  }
  .data_box{
	  width: 437px;
	  height: 142px;
	  border-top: 1px solid #535A6B;
	  padding: 10px 0;
	  box-sizing: border-box;
	  position: relative;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .one,.two{
	  display: flex;
	  align-items: center;
	  justify-content: space-around;
  }
  .two{
	  margin-top: 10px;
  }
  .item{
	  width: 65px;
  }
  .label{
	  /* width: 24px; */
	  height: 12px;
	  font-size: 12px;
	  font-family: PingFang SC;
	  font-weight: 400;
	  color: #A5EEEB;
	  margin-bottom: 9px;
  }
  .number{
	  height: 12px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #FFFFFF;
  }
  .data_line{
	  width: 1px;
	  height: 35px;
	  background: #535A6B;
  }


.distribution{
	width: calc(100% - 510px);
	/* height: 352px; */
	height: 100%;
	background: rgba(10,23,56,0.7);
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
}
.d_box{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: calc(100% - 62px);
	margin-top: 20px;
}
.distribution_item{
	width:32% ;
	height: 100%;
	/* background-color: red; */
}
.distribution_item>img{
	width: 100%;
	height: 100%;
}


.ca_bottom{
	width: 100%;
	/* height:calc(100% - 382px); */
	height:calc(60% - 15px);
	/* background-color: #36CFC9; */
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.b_left{
	width: 480px;
	height: 100%;
	background: rgba(10,23,56,0.7);
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
	
}
.left_chart_box{
	width: 100%;
	height: calc(100% - 42px);
}
.left_chart_box div{
	width: 100%;
	height: 50%;
	/* border-bottom: 1px solid red; */
}
.b_middle{
	width: calc(30% - 162px);
	height: 100%;
	/* display: flex;
	flex-flow: column; */
}
.b_middle .title{
	margin-bottom: 3%;
}
.b_middle_item{
	width: 100%;
	background: rgba(10,23,56,0.7);
	padding:0 20px;
	box-sizing: border-box;
	margin-bottom: 20px;
}
.b_middle_item:first-child{
	height: calc(40% - 16px);
}
.b_middle_item:nth-child(2){
	height: calc(30% - 12px);
	/* flex: 3; */
}
.b_middle_item:last-child{
	height: calc(30% - 12px);
	/* flex: 2; */
}
.data_item{
	  float: left;
	  margin-right: 20%;
	  font-size: 10px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #BFF2F0;
	  margin-bottom:2%;
	  /* height: 16px; */
	  display: flex;
	  align-items: center;
	  /* margin-top: 3%; */
  }
  .data_item:nth-child(2n+1){
	  margin-right: 0;
  }
  .data_item div{
	  display: inline-block;
	  min-width: 60px!important;
	  text-align:justify;
	  height: 12px;
	  line-height: 12px;
	  
  }
  .data_item div i{
    display:inline-block;
	height: 1px;
    width:100%;
  }
  .data_number{
	  display: inline-block;
	  min-width: 50px;
	  margin-left: 12px;
	  font-size: 10px;
	  font-family: PingFang SC;
	  font-weight: bold;
	  color: #FFFFFF;
  }
  .box{
	  height: calc(100% - 60px);
	  overflow: auto;
  }
  .warn_item{
	  width: 100%;
	  /* height: 16px; */
	  font-size: 12px;
	  font-family: PingFang SC;
	  color: #BFF2F0;
	  margin-bottom: 5%;
	  display: flex;
	  justify-content: start;
	  align-items: center;
  }
  .warn_item:first-child{
	  margin-top: 10px;
  }
  .warn_item span:nth-child(2){
	  text-align: left;
  }
  .quan{
	  display: inline-block;
	  width: 6px;
	  height: 6px;
	  background-color:#ED9845;
	  margin-right: 10px;
  }
  .red{
	  display: inline-block;
	  width: 6px;
	  height: 6px;
	  margin-right: 10px;
	  background-color: #CF3636!important;
  }
.b_right{
	width: calc(70% - 378px);
	height: 100%;
}
.b_right_top{
	width: 100%;
	height: calc(50% - 10px);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.b_right_top_item{
	width: calc(50% - 15px);
	height: 100%;
    background: rgba(10,23,56,0.7);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
}
.list_title{
	width: 100%;
	height: 14px;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 400;
	color: #A5EEEB;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin:15px 0 ;
}
 .list_title div:first-child{
	  width: 140px;
	  text-align: center;
  }
  .list_title div{
  	  width: 120px;
  	  text-align: center;
  }
.sensor_list{
	  /* width: 508px; */
	  width: 100%;
	 /* height: 324px; */
	  height: calc(100% - 80px);
	  overflow: auto;
	  margin-top: 10px;
  }
  .sensor_item{
	  /* width: 500px; */
	  width: calc(100% - 5px);
	  height: 30px;
	  background-color: rgba(11,19,38,0.5);
	  margin-bottom: 8px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  color: #FFFFFF;
	  font-size: 12px;
	  padding:0 10px;
	  box-sizing: border-box;
  }
  .sensor_item div:first-child{
	  width: 140px;
	  /* text-align: left; */
  }
  .sensor_item div{
  	  width: 120px;
  	  /* text-align: left; */
  }
  .green{
	  color:#59DA6E ;
  }
  .red1{
  	  color:#DA5959 ;
  }
  .yellow{
	  color: #ED9845;
  }
  .blue{
	  color: #36CFC9;
  }
.b_right_bottom{
	width: 100%;
	height: calc(50% - 10px);
	background: rgba(10,23,56,0.7);
	padding: 0 20px 20px 20px;
	box-sizing: border-box;
}
.nyfx_chart{
	height: calc(100% - 42px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
 #myChart_comprehensive1{
	 width: 100%;
	 height:100%;
 }
 #myChart_comprehensive2{
 	 width: 100%;
 	 height:100%;
 }
 #myChart_comprehensive3{
 	 width: 100%;
 	 height:calc(100% - 15px);
	 margin-top: 10px;
 }
 #myChart_comprehensive4{
 	width: 100%;
 	height:calc(100% - 15px);
	margin-top: 10px;
 }
 #myChart_comprehensive5{
 	width: 100%;
 	height:calc(100% - 20px);
 	margin-top: 10px;
 }
 #myChart_comprehensive6{
 	width: 70%;
 	height:calc(100% - 10px);
 	margin-top: 10px;
 }
 #myChart_comprehensive7{
 	width: 30%;
 	height:calc(100% - 10px);
 	margin-top: 10px;
 }
</style>



